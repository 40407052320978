<template>

	<form action="/api/v1/customers/pricing/make-payment/" method="POST" class="">
		<input type="hidden" v-model="stripeToken" name="stripeToken">
		<input type="hidden" v-model="stripeEmail" name="stripeEmail">
		<div class="row" style="margin-bottom:0px; padding-bottom:0px;">
			<div class="center" style="margin-bottom:0px; padding-bottom:0px;">
				<p v-if="submitPayment"  style="display: block; background-color: red; color: white; padding:5px;">Submitting payment! Please wait...</p>
				<p v-if="paymentSuccess"  style="display: block; background-color: green; color: white; padding:5px;">Payment successful! Redirecting...</p>
				<p v-if="paymentFailiure"  style="display: block; background-color: red; color: white; padding:5px;">Something went wrong. Hold tight...</p>
				<input type="submit" v-on:click.once="disable" :disabled="isActive"  @click.prevent="buy" value="CLICK HERE TO PAY BY CARD">
			</div>
		</div>
	</form>
</template>

<script>

export default {
	components: {

	},

	mounted() {
		if(this.cost == ""){
			this.isActive = false;
		}
		// window.onbeforeunload = () => 'Are you sure you want to leave?'
	},

	data() {
		return {
			stripeEmail: '',
			stripeToken: '',
			isActive: false,
			submitPayment: false,
			paymentSuccess: false,
			paymentFailiure: false,
			userId: Expost.userId,
			currency: Expost.currency,
			cost: Expost.cost,
			duration: Expost.duration,
			payment_title: Expost.payment_title,
			payment_type: Expost.payment_type,
		}
	},

	created(){
		this.stripe = StripeCheckout.configure({

			key: Expost.stripeKey ,
			locale: "auto",
			email: Expost.userEmail,

			token: (token) => {

				this.stripeToken = token.id;
				this.stripeEmail = token.email;
				this.submitPayment = true;

				axios.post('/api/v1/customers/pricing/make-payment/', this.$data, this.cost, this.duration, this.payment_title, this.payment_type, this.stripeToken)
				.then(response => {
					this.isActive = true;
					this.submitPayment = false;
					this.paymentSuccess = true;
					if(this.payment_type == 1){
						window.location = '/control-panel/#id-verification';
					} else {
						window.location = '/control-panel/#account';
					}

				})
				.catch(error => {
					this.isActive = false;
					this.submitPayment = false;
					this.paymentFailiure = true;
					window.location = '/';
				});
			},

		});
	},

	methods: {

		buy(){
			this.stripe.open({
				name: "Expost Secure Checkout",
				description: this.payment_title,
				zipCode: false,
				currency: this.currency,
				amount: this.cost * 100
			});
		},

		disable (event) {
			this.isActive = true
		},

	}
};
</script>
