<template>

	<div>
		<form class="payzone-form centeredForm" id="payzone-payment-form" name="payzone-payment-form" target="_self" method="POST" role="form" action="">
			<!-- OrderDetails -->

			<!-- <h4 class="tight" style="text-align: center; padding-bottom: 10px;">Pay by card</h4> -->

			<div class="grid_5 clearleft">
				<label><strong>Account Holder's name:</strong></label>
			</div>
			<div class="grid_7 omega">
				<input name="CardName" id="CardName" type="text" value="">
			</div>

			<div class="grid_5 clearleft">
				<label><strong>Card Number:</strong></label>
			</div>
			<div class="grid_7 omega">
				<input name="CardNumber" id="CardNumber" type="text" value="">
			</div>

			<div class="grid_5 clearleft">
				<label><strong>Expiry Date (Month)</strong></label>
			</div>
			<div class="grid_7 omega">
				<input name="ExpiryDateMonth" id="ExpiryDateMonth" type="text" value="">
			</div>

			<div class="grid_5 clearleft">
				<label><strong>Expiry Date (Year)</strong></label>
			</div>
			<div class="grid_7 omega">
				<input name="ExpiryDateYear" id="ExpiryDateYear" type="text" value="">
			</div>

			<div class="grid_5 clearleft">
				<label><strong>CV2</strong></label>
			</div>
			<div class="grid_7 omega">
				<input name="CV2" id="CV2" type="text" value="">
			</div>

			<div class="grid_12 omega textCenter">
				<input name="" type="submit" v-on:click.once="disable" :disabled="isActive"  @click.prevent="buy" value="MAKE PAYMENT">
			</div>
			<div class="clear"></div>

		</form>
	</div>

</template>

<script>

	export default {
		components: {

		},

		mounted() {
			if(this.cost == ""){
				this.isActive = false;
			}
		// window.onbeforeunload = () => 'Are you sure you want to leave?'
	},

	data() {
		return {
			stripeEmail: '',
			stripeToken: '',
			isActive: false,
			submitPayment: false,
			paymentSuccess: false,
			paymentFailiure: false,
			userId: Expost.userId,
			currency: Expost.currency,
			cost: Expost.cost,
			duration: Expost.duration,
			payment_title: Expost.payment_title,
			payment_type: Expost.payment_type,
		}
	},

	created(){
		this.stripe = StripeCheckout.configure({

			key: Expost.stripeKey ,
			locale: "auto",
			email: Expost.userEmail,

			token: (token) => {

				this.stripeToken = token.id;
				this.stripeEmail = token.email;
				this.submitPayment = true;

				axios.post('/api/v1/customers/pricing/make-payment/', this.$data, this.cost, this.duration, this.payment_title, this.payment_type, this.stripeToken)
				.then(response => {
					this.isActive = true;
					this.submitPayment = false;
					this.paymentSuccess = true;
					if(this.payment_type == 1){
						window.location = '/control-panel/#id-verification';
					} else {
						window.location = '/control-panel/#account';
					}

				})
				.catch(error => {
					this.isActive = false;
					this.submitPayment = false;
					this.paymentFailiure = true;
					window.location = '/';
				});
			},

		});
	},

	methods: {

		buy(){
			this.stripe.open({
				name: "Expost Secure Checkout",
				description: this.payment_title,
				zipCode: false,
				currency: this.currency,
				amount: this.cost * 100
			});
		},

		disable (event) {
			this.isActive = true
		},

	}
};
</script>
