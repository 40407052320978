<template>
	<div class="">
		<div class="row" style="margin-top:0px; padding-top:0px;">
			<div class="center" style="margin-top:0px; padding-top:0px;">
				<p v-if="submitPayment"  style="display: block; background-color: red; color: white; padding:5px;">Submitting payment! Please wait...</p>
				<p v-if="paymentSuccess"  style="display: block; background-color: green; color: white; padding:5px;">Payment successful! Redirecting...</p>
				<p v-if="paymentFailiure"  style="display: block; background-color: red; color: white; padding:5px;">Something went wrong. Hold tight...</p>
				<div id="paypal-button" ></div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		components: {

		},

		mounted() {
		//
	},

	data() {
		return {
			isActive: false,
			submitPayment: false,
			paymentSuccess: false,
			paymentFailiure: false,
			currency: Expost.currency,
			cost: Expost.cost,
			duration: Expost.duration,
			payment_title: Expost.payment_title,
			username: Expost.username,
			payment_type: Expost.payment_type,
			environment: Expost.environment
		}
	},

	created(){
		this.loadPaypal();
	},

	methods: {

		loadPaypal(){
			var self = this;


			paypal.Button.render({

			    // Configure environment
			    env: self.environment,
			    client: {
			    	sandbox: 'AVg2gRRmKzYZxPRKih5T_bAKDAHxdxB7uHOjUm5fGoq5oZuQOrWCt3AUcWXgyRBZlKUFJOFjlkfRcXSD',
			    	production: 'ATRGmtkyfs1khBytzw4pts5L6TasfB5RyijkyfL_u6eYJib0dGg6gfB0p41mfVmwNyGkxWJeC7Rfledd'
			    },
			    // Customize button (optional)
			    locale: 'en_GB',
			    style: {
			    	size: 'medium',
			    	color: 'gold',
			    	shape: 'rect',
			    	layout: 'horizontal',
			    	fundingicons: 'true',
			    },
			    funding: {
			    	allowed: [ paypal.FUNDING.CREDIT ]
			    },

			    // Enable Pay Now checkout flow (optional)
			    commit: true,

			    // Set up a payment
			    payment: function(data, actions) {

			    	return actions.payment.create({
			    		transactions: [{
			    			amount: {
			    				total: self.cost,
			    				currency: self.currency,
			    				details: {
			    					subtotal: self.cost,
			    				}
			    			},
			    			description: self.payment_title
			    		}]
			    	});
			    },

			    // Execute the payment
			    onAuthorize: function(data, actions) {
			    	return actions.payment.execute().then(function() {
			    		self.submitPayment = true;
			    		axios.post('/api/v1/customers/pricing/make-paypal-payment/', {
			    			paymentId: data.paymentID,
			    			cost: self.cost,
			    			duration: self.duration,
			    			payment_title: self.payment_title,
			    			payment_type: self.payment_type
			    		})
			    		.then(response => {
			    			self.submitPayment = false;
			    			self.paymentSuccess = true;
			    			if(self.payment_type == 1){
			    				window.location = '/control-panel/#id-verification';
			    			} else {
			    				window.location = '/control-panel/#account';
			    			}
			    		})
			    		.catch(error => {
			    			self.submitPayment = false;
			    			self.paymentFailiure = true;
			    			window.location = '/';
			    		});
			    	});
			    },
			    onError: function (err) {
			    	console.log(err);
			    }
			}, '#paypal-button');
		},

	}
};


</script>
