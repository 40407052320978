<template>
	<div class="">
		<label class="inlinelabel">ITEMS: </label>
		<select v-model="letters" class="fixedShortInput">
			<option value="0">0</option>
			<option value="1">1</option>
			<option value="2">2</option>
			<option value="3">3</option>
			<option value="4">4</option>
			<option value="5">5</option>
			<option value="6">6</option>
			<option value="7">7</option>
			<option value="8">8</option>
			<option value="9">9</option>
			<option value="10">10</option>
		</select>

		<select v-model="large_letters" class="fixedShortInput">
			<option value="0">0</option>
			<option value="1">1</option>
			<option value="2">2</option>
			<option value="3">3</option>
			<option value="4">4</option>
			<option value="5">5</option>
			<option value="6">6</option>
			<option value="7">7</option>
			<option value="8">8</option>
			<option value="9">9</option>
			<option value="10">10</option>
		</select>

		<select v-model="parcels" class="fixedShortInput">
			<option value="0">0</option>
			<option value="1">1</option>
			<option value="2">2</option>
			<option value="3">3</option>
			<option value="4">4</option>
			<option value="5">5</option>
			<option value="6">6</option>
			<option value="7">7</option>
			<option value="8">8</option>
			<option value="9">9</option>
			<option value="10">10</option>
		</select>


		<div class="row despatchSection">
			<label class="inlinelabel">POSTAGE COST <strong>{{ this.currency_symbol }}</strong></label>
			<input type="number" v-model="postage_cost" id="postage_cost" class="codValue" step="0.01" v-model:value="postage_cost" string-to-number/>
			<button type="button" class="button brownBg"  @click.prevent="calculateCost"> CALCULATE </button>
		</div>

		<div class="row despatchSection">
			<label class="inlinelabel">TOTAL <strong>{{ this.currency_symbol }}</strong></label>
			<input type="text" v-model="total_outpost" id="total_outpost" class="totalPostageValue" step="0.01"  readonly />
			<button class="payPostage button solidBlue" type="submit" :disabled="isActive" id="send" v-on:click="send">SEND</button>
			<a href="" class="declinePostage button redBg" :disabled="isDeclineActive" @click.prevent="decline">DECLINE</a>
		</div>
	</div>
</template>

<script>



export default {

	components: {

	},

	mounted() {
		//
	},

	data() {
		return {
			isActive: true,
			isDeclineActive: false,
			userId: Expost.userId,
			surcharge: Expost.surcharge,
			currency_symbol: Expost.currency_symbol,

			letters: 0,
			large_letters: 0,
			parcels: 0,
			letters_cost:  0.50,
			large_letters_cost: 1,
			parcels_cost: 1.50,
			letters_total_cost: null,
			large_letters_total_cost: null,
			parcels_total_cost: null,
			items_total: 0,
			postage_cost: null,
			total: null,
			total_outpost: null,
			markup: null,
			markup_percentage: 30
		}
	},

	created(){
		//
	},

	methods: {
		calculateCost(){
			this.isActive = false;
			// Calculate the items
			this.letters_total_cost = this.letters * parseFloat(this.letters_cost);
			this.large_letters_total_cost = this.large_letters * parseFloat(this.large_letters_cost);
			this.parcels_total_cost = this.parcels * parseFloat(this.parcels_cost);
			this.items_total = this.letters_total_cost + this.large_letters_total_cost + this.parcels_total_cost;

			// Markup
			this.markup = (this.postage_cost / 100) * parseInt(this.markup_percentage);
			// var total = parseFloat(this.items_total) + parseFloat(this.markup);

			// Totals
			this.total = parseFloat(this.items_total) + parseFloat(this.postage_cost) + parseFloat(this.markup) + parseFloat(this.surcharge);
			// this.total_outpost = parseFloat(this.total).toFixed(2);
			this.total_outpost = parseFloat(this.total).toFixed(2);

			this.isActive = false;

			// num of items * handling + postagecost * 130% + 2.50 () + numLetters 0.50 + numParcels etc
		},

		send(){

			this.letters_total_cost = this.letters * parseFloat(this.letters_cost);
			this.large_letters_total_cost = this.large_letters * parseFloat(this.large_letters_cost);
			this.parcels_total_cost = this.parcels * parseFloat(this.parcels_cost);
			this.items_total = this.letters_total_cost + this.large_letters_total_cost + this.parcels_total_cost;

			axios.post('/api/v1/customers/mailcontrol/despatch/send/', {
				userId: Expost.userId,
				cost: this.total_outpost,
				letters: this.letters,
				large_letter: this.large_letters,
				parcels: this.parcels,
			})
			.then(response => {
				this.isActive = true;
				window.location = "/mail-control/despatch/" + Expost.userId + "/edit"
			})
			.catch(error => {
				this.isActive = true;
			});
		},

		decline(){
			axios.post('/api/v1/customers/mailcontrol/despatch/decline/', {
				userId: Expost.userId,
			})
			.then(response => {
				this.isDeclineActive = false;
			})
			.catch(error => {
				this.isDeclineActive = true;
			});
		},
	}
};


</script>
